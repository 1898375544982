<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'crypto-index' }" />

    <template v-if="cryptocurrency">
      <h2 class="mb-4">{{ cryptocurrency.name }}</h2>

      <v-sheet elevation="1">
        <v-tabs background-color="deep-purple secondary" center-active dark hide-slider>
          <v-tab>Обзор</v-tab>
          <v-tab>Склонения</v-tab>

          <v-tab-item>
            <cryptocurrency-summary
              :cryptocurrency="cryptocurrency"
              :updating="updating"
              @update-cryptocurrency="updateCryptocurrency"
            />
          </v-tab-item>

          <v-tab-item>
            <cryptocurrency-declensions
              :cryptocurrency="cryptocurrency"
              :updating="updating"
              @update-cryptocurrency="updateCryptocurrency"
            />
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Криптовалюта по коду "{{ code }}" не найдена</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import CryptocurrencySummary from "~/components/crypto/_code/summary";
import CryptocurrencyDeclensions from "~/components/crypto/_code/declensions";

export default {
  data() {
    return {
      cryptocurrency: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchCryptocurrency();
  },
  computed: {
    code() {
      return this.$route.params.code;
    }
  },
  methods: {
    async fetchCryptocurrency() {
      this.loading = true;

      try {
        this.cryptocurrency = await this.$axios.$get(`cryptocurrencies/${this.code}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получена информация по коду: "${this.code}"` + html);
      } finally {
        this.loading = false;
      }
    },
    async updateCryptocurrency(payload) {
      this.updating = true;

      try {
        this.cryptocurrency = await this.$axios.$patch(`cryptocurrencies/${this.code}`, JSON.stringify(payload), {
          headers: {
            "Content-Type": "application/json"
          }
        });

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, ["logo"]).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    }
  },

  components: {
    CryptocurrencySummary,
    CryptocurrencyDeclensions
  }
};
</script>
