<template>
  <div>
    <v-row class="props-list">
      <v-col md="2">Код</v-col>
      <v-col md="10">{{ cryptocurrency.code }}</v-col>

      <v-col md="2">Название</v-col>
      <v-col md="10">{{ cryptocurrency.name }}</v-col>

      <v-col md="2">Название на RU</v-col>
      <v-col md="10">
        <v-text-field :value="cryptocurrency.nameRu" label="Перевод на RU" @input="setField('nameRu', $event)" />
      </v-col>

      <v-col md="2">Описание</v-col>
      <v-col md="10">
        <wysiwyg-editor class="mt-4" :value="cryptocurrency.description" @input="setField('description', $event)" />
      </v-col>

      <v-col md="2">SEO описание</v-col>
      <v-col md="10">
        <wysiwyg-editor
          class="mt-4"
          :value="cryptocurrency.seoDescription"
          @input="setField('seoDescription', $event)"
        />
      </v-col>

      <v-col md="2"> Описание mobile-friendly </v-col>

      <v-col md="10">
        <v-textarea
          class="mt-4"
          :value="cryptocurrency.mobileDescription"
          dense
          @input="setField('mobileDescription', $event)"
        />
      </v-col>

      <v-col class="font-weight-regular" md="12">
        <v-checkbox v-model="isActiveProxy" class="my-0" label="Криптовалюта активна" hide-details />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    cryptocurrency: {
      type: Object,
      required: true
    }
  },
  computed: {
    isActiveProxy: {
      get() {
        return this.cryptocurrency.isActive;
      },
      set(value) {
        this.setField("isActive", value);
      }
    }
  }
};
</script>
