<template>
  <div>
    <v-row class="props-list">
      <v-col md="2">Справочные данные</v-col>
      <v-col md="10">
        <v-text-field :value="reference.site" label="URL сайта" @input="setField('reference.site', $event)" />

        <v-text-field
          :value="reference.techSpecs"
          label="URL технической документации"
          @input="setField('reference.techSpecs', $event)"
        />

        <v-text-field
          :value="reference.info"
          label="URL справочных данных"
          @input="setField('reference.info', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    reference: {
      type: Object,
      required: true
    }
  }
};
</script>
