<template>
  <v-row class="props-list">
    <v-col md="2">Иминительный</v-col>
    <v-col md="10">
      <v-text-field
        :value="cases.nominative"
        label="Иминительный"
        @input="setField(`declensions.${typeCases}.nominative`, $event)"
      />
    </v-col>

    <v-col md="2">Родительный</v-col>
    <v-col md="10">
      <v-text-field
        :value="cases.genitive"
        label="Родительный"
        @input="setField(`declensions.${typeCases}.genitive`, $event)"
      />
    </v-col>

    <v-col md="2">Дательный</v-col>
    <v-col md="10">
      <v-text-field :value="cases.dative" label="Дательный" @input="setField('declensions.cases.dative', $event)" />
    </v-col>

    <v-col md="2">Винительный</v-col>
    <v-col md="10">
      <v-text-field
        :value="cases.accusative"
        label="Винительный"
        @input="setField(`declensions.${typeCases}.accusative`, $event)"
      />
    </v-col>

    <v-col md="2">Творительный</v-col>
    <v-col md="10">
      <v-text-field
        :value="cases.ablative"
        label="Творительный"
        @input="setField(`declensions.${typeCases}.ablative`, $event)"
      />
    </v-col>

    <v-col md="2">Предложный</v-col>
    <v-col md="10">
      <v-text-field
        :value="cases.prepositional"
        label="Предложный"
        @input="setField(`declensions.${typeCases}.prepositional`, $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    cases: {
      type: Object,
      required: true
    },
    typeCases: {
      type: String,
      default: "cases",
      validator(type) {
        return ["cases", "pluralCases"].includes(type);
      }
    }
  }
};
</script>
