<template>
  <div class="pa-3">
    <cryptocurrency-overview :cryptocurrency="cryptocurrency" @change-field="changeField" />

    <v-divider class="my-3" />

    <cryptocurrency-reference :reference="cryptocurrency.reference" @change-field="changeField" />

    <v-divider class="my-3" />

    <cryptocurrency-media v-model="logo" :cryptocurrency="cryptocurrency" />

    <v-divider class="my-3" />

    <div class="text-right">
      <v-btn color="accent" :loading="updating" @click="handleSave">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";
import { encodeFileToBase64 } from "~/core/helpers";

import { UpdateCryptocurrencyDto } from "~/libs/cryptocurrency/cryptocurrency";

import CryptocurrencyOverview from "./overview";
import CryptocurrencyMedia from "./media";
import CryptocurrencyReference from "./reference";

export default {
  mixins: [editorForm],
  props: {
    cryptocurrency: {
      type: Object,
      required: true
    },
    updating: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      logo: null
    };
  },
  watch: {
    cryptocurrency() {
      this.logo = null;
    }
  },
  methods: {
    async handleSave() {
      const logoConverted = await encodeFileToBase64(this.logo);

      this.$emit("update-cryptocurrency", new UpdateCryptocurrencyDto(this.cryptocurrency, logoConverted));
    },
    changeField(payload) {
      this.commitChange(this.cryptocurrency, payload);
    }
  },
  components: {
    CryptocurrencyOverview,
    CryptocurrencyReference,
    CryptocurrencyMedia
  }
};
</script>
