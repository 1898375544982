<template>
  <div class="pa-3">
    <div class="text-h6 font-weight-regular mb-4">По падежам</div>

    <currency-declensions-cases :cases="cryptocurrency.declensions.cases" @change-field="changeField" />

    <v-divider class="my-3" />

    <div class="text-h6 font-weight-regular mb-4">По падежам во множестве</div>

    <currency-declensions-cases
      :cases="cryptocurrency.declensions.pluralCases"
      type-cases="pluralCases"
      @change-field="changeField"
    />

    <v-divider class="my-3" />

    <div class="text-right">
      <v-btn color="accent" :loading="updating" @click="handleSave">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";

import CurrencyDeclensionsCases from "./cases";

export default {
  mixins: [editorForm],
  props: {
    cryptocurrency: {
      type: Object,
      required: true
    },
    updating: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleSave() {
      this.$emit("update-cryptocurrency", { declensions: this.cryptocurrency.declensions });
    },
    changeField(payload) {
      this.commitChange(this.cryptocurrency, payload);
    }
  },
  components: {
    CurrencyDeclensionsCases
  }
};
</script>
