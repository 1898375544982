export class UpdateCryptocurrencyDto {
  constructor(cryptocurrency, logo = null) {
    this.logo = logo;
    this.nameRu = cryptocurrency.nameRu;
    this.description = cryptocurrency.description;
    this.seoDescription = cryptocurrency.seoDescription;
    this.mobileDescription = cryptocurrency.mobileDescription;
    this.isActive = cryptocurrency.isActive;
    this.reference = cryptocurrency.reference;
  }
}
